import React from 'react';
import Placeholder from '../../../core/Components/Placeholder';

const FullWidthContainerVideo = ({ rendering }) => {
  return (
    <div className="sl l-padding is-full-width is-full-width-video">
      <Placeholder name="content-full-width" rendering={rendering} />
    </div>
  );
};

export default FullWidthContainerVideo;
